import React from "react"
import AllArtists from "../components/artists/AllArtists"

const Artists = () => {
  return (
    <>
      <AllArtists />
    </>
  )
}

export default Artists
